import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  Carousel,
  Row,
  Col,
  List,
  Typography,
  Button,
  Empty,
  Tabs,
  Image,
} from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const { TabPane } = Tabs;

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "top",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }

  componentDidMount() {
    fetch(
      "https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition, jobDescription } = this.state;
    const { pexUnits } = this.props;

    const html = "${eventContent}";

    function callback(key) {
      console.log(key);
    }

    return (
      <div>
        <section className="block-sl">
          {" "}
          {/* <div className="banner-item banner-2x banner-bg-download-center color-inher">
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              style={{ width: "12em", marginBottom: "1em" }}
            />
            <h3 className="f-weight-300">
              <strong>JOB LISTINGS</strong>
              <br />
              AVAILABLE VACANCIES.
            </h3>
          </div> */}
          <div className="container-custom">
            <div className="row" style={{ textAlign: "center" }}>
              {/* <div
                className='col-sm-12 col-md-12 col-lg-12'
                style={{ zIndex: 10, marginBottom: "2em" }}
              >
                {this.state.pexUnits.length > 2 ?
                  <List
                    bordered
                  > {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.jobTitle ? (
                      <List.Item>
                        <List.Item.Meta
                          // avatar={<Avatar src={item.avatar} />}
                          title={<span>{pexUnits.jobTitle} <div style={{
                            float: window.isPhone ? "" : "right", color: "lightgray", marginTop: window.isPhone ? "0.5em" : ""
                          }}>
                            Published:  {pexUnits.datePosted}
                            <br />
                          </div></span>}
                          description={<span>Location: <span style={{ color: "black" }}>{pexUnits.jobLocation}</span><br />{pexUnits.jobEmail ? <span>Contact: <a href={"mailto:" + pexUnits.jobEmail}> {pexUnits.jobEmail}</a><br /><br />
                            <div style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: pexUnits.jobDescription }} />
                            <a href={"mailto:" + pexUnits.jobEmail + "?Subject=" + pexUnits.jobTitle}>
                              <Button
                                style={{

                                  border: "1px solid #F91700",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  borderRadius: "3em",
                                  backgroundColor: "#F91700",
                                  color: "white",
                                  height: "40px",

                                  paddingLeft: "3em",
                                  paddingRight: "3em",
                                  textShadow: "none",
                                  margin: "auto",
                                  marginTop: "1em",
                                }}
                                type='primary'
                                htmlType='submit'
                              >
                                Send CV
                              </Button>{" "}
                            </a>
                          </span>

                            : null}
                          </span>}
                        />



                      </List.Item>

                    ) : null;
                  })}
                  </List>
                  : <Empty description={
                    <span>
                      No Vacancies Available
                    </span>
                  } />}
              </div> */}
              {/* <span>
                No Vacancies Available
              </span> */}

              <Tabs
                defaultActiveKey="1"
                onChange={callback}
                style={{
                  margin: "auto",
                  // textAlign: "center",
                }}
              >
                {/* <TabPane tab="Potchefstroom" key="1">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",
                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-potch-1.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-potch-2.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-potch-3.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane> */}
                {/* <TabPane tab="Lichtenburg" key="1">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",

                      marginTop: "3em",
                    }}
                  >

                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-lichtenburg.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>


                  </Row>
                </TabPane> */}
                <TabPane tab="General" key="1">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",

                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-general.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tab="Klerksdorp" key="2">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",

                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-klerksdorp.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane>*/}
                <TabPane tab="Mahikeng" key="2">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",
                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-mahikeng.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tab="Potchefstroom" key="3">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",
                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-potchefstroom.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane> */}
                {/* <TabPane tab="Lichtenburg" key="5">
                  <Row
                    gutter={10}
                    style={{
                      margin: "auto",
                      marginTop: "3em",
                    }}
                  >
                    <Col
                      xs={24}
                      md={7}
                      style={{ marginTop: "0.7em", margin: "auto" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={`${process.env.PUBLIC_URL}/Vacancy-images/vacancy-lichtenburg.jpg`}
                        alt="vacancy-image"
                      />
                    </Col>
                  </Row>
                </TabPane> */}
              </Tabs>
            </div>
          </div>
        </section>{" "}
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;

    return (
      <div id="wrap" className="color1-inher">
        {this.renderSearchBar()}
        <div id="wrap-body" className="p-t-lg-45">
          <div className="container-custom">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default withRouter(SharedLayout);
