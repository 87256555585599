import React from "react";
import { connect } from "react-redux";
import { Link, withRouter, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tabs,
  Badge,
  Image,
  Radio,
  Space,
  Checkbox,
  Form,
  Select,
  Input,
  Modal,
  Collapse,
  Button,
  Spin
} from "antd";
import CurrencyFormat from "react-currency-format";
import {
  WhatsAppOutlined,
  MailOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import config from './server.config';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Traders, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledCheckbox = styled(Checkbox)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #777 !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: white !important;
    border: 0px solid white !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #F91700 !important;
}
.ant-checkbox-checked::after {
  background-color: transparent !important;
  border-color: red !important;
  border: 0px solid red !important;
}
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = value => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = value => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = value => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = value => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = value => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={amountValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={amountValue => <div>{amountValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={30000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={depositValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={depositValue => <div>{depositValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className="color-red" style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className="color-red" style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className="color-red" style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      monthlyRepayment < 0 ? "0" : monthlyRepayment
    );

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className="finance-box-p color-red" style={{ marginTop: "-1em" }}>
        <CurrencyFormat
          value={monthlyRepaymentLast}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"R "}
          //format='R ### ### ### ###'
          renderText={monthlyRepaymentLast => <div>{monthlyRepaymentLast}</div>}
        />
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    let monthlyRepaymentLast = Math.round(
      monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={monthlyRepaymentLast => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    let monthlyRepaymentallLast = Math.round(
      monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentallLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={monthlyRepaymentallLast => (
              <div>{monthlyRepaymentallLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      amount - deposit < 0 ? "0" : amount - deposit
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={monthlyRepaymentLast => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className="finance-box-p color-red">{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className="finance-box-flex">
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text="Monthly Repayment"
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const onChange = e => {
  console.log(`checked = ${e.target.checked}`);
};
class StockDetailsKlerksdorp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      stock: [],
      valueRadio: '',
      firstName: '',
      surname: '',
      cellPhone: '',
      loading: false,
      // brand: this.props.selectedStock.make,
      // price: this.props.selectedStock.price,
      // stockNr: this.props.selectedStock.stockNumber,
      // vin: this.props.selectedStock.vehicleStockNumber,
      // year: this.props.selectedStock.modelYear,
      // model: this.props.selectedStock.model,
      leadReference: '',
      errorMessage: '',
      submitDisabled: false,
      test: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    console.log(this.props.selectedStock);
    document.documentElement.scrollTop = 0;

    var stock = this.props.stock;
    fetch("https://www.devvcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async res => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
  }
  onChangeRadio = e => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadio: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("https://www.megatheron.co.za/mega-theron-traders");
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("https://www.megatheron.co.za/mega-theron-traders");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = values => {
    // var {
    //   email,
    //   stockNumber,
    //   vehicleStockNumber,
    //   make,
    //   series,
    //   modelDesc,
    //   modelYear,
    //   valueRadio,
    // } = this.props.selectedStock;
    //var { valueRadio } = this.state.valueRadio;
    const MSG = {
      // to: "ianels182@gmail.com",
      // to: values.email,
      to: "angelique@megatheron.co.za",
      from: "no-reply@vmg.co.za",
      //to: [  //"ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New Vehicle Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Vehicle Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Vehicle Enquiry from <br /> Mega Theron Traders website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.firstName} ${values.surname}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.cellPhone}<br />
                          <strong> VIN Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.stockNumber}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make}<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.series}<br />
                          <strong> Derivative </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.modelDesc}<br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.modelYear}<br /> 
                          
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2025@Mega Theron Traders</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        name: "",
        number: "",
        userEmail: "",
      });
    });
    const { id } = useParams();
  };


  render() {
    console.log(this.props.selectedStock);

    const { location } = this.props;
    const url = location.pathname;
    const selectedId = url.split("/").pop();

    var {
      make,
      series,
      modelDesc,
      modelYear,
      email,
      number,
      regDate,
      extColour,
      intColour,
      retailPriceIncl,
      fuelType,
      capacityCC,
      bodyType,
      transmission,
      engineSize,
      vehicleDrive,
      unladenWeight,
      axles,
      doors,
      cylinders,
      passengers,
      immobiliser,
      dateBuilt,
      extrasAndAccessories,
      comments,
      price,
      branch,
      brand,
      email,
      onSale,
      condition,
      mileage,
      fuelTankSize,
      degreeCamera,
      fourWheelDrive,
      abs,
      driverAssistance,
      airBags,
      aircon,
      alarm,
      mobileCarPlay,
      automaticLights,
      automaticWipers,
      autonomousBraking,
      blindSpotMonitoring,
      bluetooth,
      brakeAssist,
      climateControl,
      cruiseControl,
      daytimeLights,
      electricParkingBrake,
      elctricWindows,
      electronicStability,
      collisionWarning,
      headDisplay,
      heatedSeats,
      hillAssist,
      hillControl,
      keylessEntry,
      laneWarning,
      laneAssist,
      leatherSeats,
      ledLights,
      navigation,
      paddleShift,
      parkControl,
      powerBoot,
      powerSeat,
      remoteStart,
      reverseCamera,
      sunroof,
      touchScreen,
      towbar,
      signRecognition,
      tyreMonitor,
      usbPort,
      voiceRecognition,
      warranty,
      wirelessCharging,
      xenonLights,
      frontLeftImage,
      frontImage,
      frontRightImage,
      rightSideImage,
      wheelsImage,
      backRightImage,
      backImage,
      backLeftImage,
      leftSideImage,
      bootImage,
      frontSeatImage,
      backSeatImage,
      dashPassengerImage,
      dashDriverImage,
      dashDriverSideImage,
      radioImage,
      radioCloseImage,
      gearsImage,
      steeringImage,
      spareKeyImage,
      branchImage,
      vehicleStockNumber,
    } = this.props.selectedStock;

    const generateEmailLink = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock._id && stock._id.includes(selectedId)) {
        const subject = encodeURIComponent(
          `Check out this vehicle: ${stock.make} ${stock.modelDesc}`
        );
        const body = encodeURIComponent(
          `Make: ${stock.make}\n` +
          `Model: ${stock.modelDesc}\n` +
          `Price: R${stock.price}\n` +
          `Branch: ${stock.megaDealers}\n` +
          `Year: ${stock.year}\n` +
          `Condition: ${stock.condition}\n` +
          `View Now: https://www.megatheron.co.za${url}`
        );

        // Construct the mailto link
        return `mailto:?subject=${subject}&body=${body}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };
    const generateEmailLinkReserve = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock._id && stock._id.includes(selectedId)) {
        const subject = encodeURIComponent(
          `I'm interested in learning more about this vehicle: ${stock.Brand} ${stock.Model}`
        );
        const body = encodeURIComponent(
          `Make: ${stock.make}\n` +
          `Model: ${stock.modelDesc}\n` +
          `Price: R${stock.price}\n` +
          `Branch: ${stock.megaDealers}\n` +
          `Year: ${stock.year}\n` +
          `Condition: ${stock.condition}\n` +
          `View Now: https://www.megatheron.co.za${url}`
        );

        // Construct the mailto link
        return `mailto:leads@vmg.co.za?subject=${subject}&body=${body}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };

    const generateWhatsAppLink = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock._id && stock._id.includes(selectedId)) {

        const message =
          `Check out this vehicle: \n` + `\n` +
          `Make: ${stock.make}\n` +
          `Series: ${stock.series}\n` +
          `Model: ${stock.modelDesc}\n` +
          `Price: R${stock.price}\n` +
          `Branch: ${stock.megaDealers}\n` +
          `Year: ${stock.year}\n` +
          `Condition: ${stock.condition}\n` +
          `View Now: https://www.megatheron.co.za${url}`

        // Encode the message for the URL
        const encodedMessage = encodeURIComponent(message);

        // Construct the WhatsApp share link
        return `https://wa.me/?text=${encodedMessage}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };

    return (
      <Spin spinning={this.state.loading} tip="Loading...">
        <div>

          {this.state.stock.map((stock, index, props) => {
            return stock._id === selectedId ? (
              <div className="col-lg-12" key={index}>
                <div className="wrap-body-inner">
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ht-breadcrumb pull-left">
                          <li className="home-act">
                            <Link to={"/mega-theron-traders"}>
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                          <li className="active">Back to Showroom</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <section className="m-t-lg-30 m-t-xs-0">
                    <div className="product_detail no-bg p-lg-0" style={{ marginBottom: "3em" }}>
                      {/*<h3 className="product-name color1-f">
                    {make} /
                    <span className="product-price color-red">
                      R{price}{" "}
      
                    </span>
                    <span className="stock-status m-l-lg-10">
                      {condition !== undefined ? condition.toUpperCase() : ""}
                    </span>
                  </h3>*/}
                      <div className="row">
                        <div className="col-md-7 col-lg-6">
                          <h4
                            style={{
                              marginTop: window.isPhone ? "1em" : "",
                              paddingLeft: "0.5em",
                              marginBottom: -20,
                            }}
                            className="product-name"
                          //style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <span className="f-22">
                              {stock.make} <br />
                              <p className="f-16">{stock.series} {stock.modelDesc}</p>
                            </span>
                          </h4>{" "}
                          <div className="product-img-lg">
                            {stock.frontLeftImage ?
                              <Image
                                style={{
                                  width: "100%",
                                  height: window.isPhone ? "100%" : "32em",
                                  objectFit: "cover",
                                }}
                                // Make sure to add a unique key for each image
                                // className="product-details-img"
                                src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontLeftImage}`}
                                alt=""
                              />
                              :

                              <img
                                style={{
                                  width: "100%",
                                  height: window.isPhone ? "100%" : "32em",
                                  objectFit: "cover",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/coming-soon.png`
                                }
                                alt=""
                              />}
                            <div className="image-zoom row m-t-lg-5 m-l-lg-ab-5 m-r-lg-ab-5">
                              <Image.PreviewGroup>
                                <Slider {...settings}>
                                  {" "}
                                  {/* {stock.frontLeftImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontLeftImage}`}
                                      alt=""
                                    />
                                  ) : null} */}
                                  {stock.frontImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.frontRightImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontRightImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.rightSideImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.rightSideImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.backRightImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.backRightImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.backImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.backImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.backLeftImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.backLeftImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.leftSideImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.leftSideImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.bootImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.bootImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.frontSeatImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontSeatImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.backSeatImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.backSeatImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.dashPassengerImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.dashPassengerImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.dashDriverImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.dashDriverImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.dashDriverSideImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.dashDriverSideImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.radioImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.radioImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.radioCloseImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.radioCloseImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.gearsImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.gearsImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.steeringImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.steeringImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                  {stock.spareKeyImage ? (
                                    <Image
                                      className="product-details-img"
                                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.spareKeyImage}`}
                                      alt=""
                                    />
                                  ) : null}
                                </Slider>
                              </Image.PreviewGroup>
                            </div>
                          </div>
                        </div>
                        {window.isPhone ? "" : <br />}
                        {window.isPhone ? "" : <br />}
                        <br />
                        <br />
                        <div className="col-md-12 col-lg-6">
                          {/* <div className="f-12 mn-cl">
                            Price
                          </div> */}
                          <div
                            className="f-40 m-b-lg-5 m-t-lg-0 mn-cl"
                            style={{
                              textAlign: window.isPhone ? "center" : "",
                              display: window.isPhone ? "none" : "",
                              paddingBottom: "1em",
                              borderBottom: "1px solid lightgrey"
                            }}
                          >
                            <CurrencyFormat
                              value={parseFloat(stock.price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"R"}
                              //format='R ### ### ### ###'
                              renderText={(value) => (
                                <div
                                  style={{
                                    lineHeight: 1,
                                    fontWeight: 500,
                                    float: window.isPhone ? "" : "right",
                                    marginTop: "-0.5em",
                                  }}
                                >
                                  {value}
                                </div>
                              )}
                            />
                            <p
                              className="f-16"
                              style={{
                                float: window.isPhone ? "" : "left",
                                marginBottom: "0em",
                              }}
                            >
                              {/* Price: */}
                              {/* Finance From{" "}
                              <span style={{ fontWeight: 600 }}>
                                R {stock.MonthlyRepayment} p/m
                              </span> */}
                            </p>
                          </div>
                          {/* <a
                            // href={
                            //   window.isPhone
                            //     ? "tel:+27825772156"
                            //     : generateEmailLinkReserve(stock, selectedId)
                            // }
                            style={{ fontSize: "20px" }}
                          >
                            <Button
                              onClick={this.showModalChat}
                              style={{
                                display: window.isPhone ? "none" : "",
                                border: "1px solid rgb(32, 26, 32)",
                                fontSize: "15px",
                                fontWeight: 600,
                                borderRadius: "0.3em",
                                backgroundColor: "rgb(32, 26, 32)",
                                color: "white",
                                height: "40px",
                                width: "100%",
                                paddingLeft: "5em",
                                paddingRight: "5em",
                                textShadow: "none",
                                margin: "auto",
                                marginTop: "1em",
                              }}
                              htmlType="submit"
                            >
                              Apply For Finance
                            </Button>
                          </a> */}
                          <div className="row m-t-lg-100 m-b-lg-0 mobile-view">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                              <Badge.Ribbon text={stock.condition} color="red">
                                <Row
                                  gutter={16}
                                  style={{
                                    //padding: "2em",
                                    boxShadow: "2px 2px 10px 2px #ecececd7",
                                    borderRadius: "1.5em",
                                  }}
                                >
                                  <Col span={24}>
                                    <Card bordered={false}>
                                      <h4
                                        style={{
                                          height: window.isPhone ? "" : "6em",
                                          paddingBottom: "0em",
                                          paddingRight: "0.2em",
                                          //borderRight: "1px solid lightgrey",
                                        }}
                                        className="product-name m-t-lg-15"
                                      //style={{ marginBottom: "0em", marginTop: "-1em" }}
                                      >
                                        <span className="f-22">
                                          {stock.make} {stock.series} <br />
                                          <p className="f-16">{stock.modelDesc}</p>
                                        </span>
                                      </h4>{" "}
                                    </Card>
                                  </Col>
                                  <Col span={24}>
                                    <Card bordered={false}>
                                      <div
                                        style={{
                                          //borderRight: "1px solid lightgrey",
                                          height: window.isPhone ? "" : "6em",
                                        }}
                                      >
                                        <div className="f-12  mn-cl">Price</div>
                                        <div className="f-30 m-b-lg-5 m-t-lg-0 mn-cl">
                                          <CurrencyFormat
                                            value={stock.price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"R"}
                                            //format='R ### ### ### ###'
                                            renderText={value => (
                                              <div style={{ lineHeight: 1 }}>
                                                {value}
                                              </div>
                                            )}
                                          />
                                        </div>
                                        {/*<p className='f-20 f-weight-500 m-b-lg-0'>
                                <CurrencyFormat
                                  value={3650}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"R"}
                                  suffix={" pm"}
                                  //format='R #### #### pm*'
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </p>{" "}*/}
                                        {/*<div className='f-15 m-t-lg-0 m-b-lg-0 m-t-lg-0'>
                                RATE CALCULATION
                              </div>*/}
                                      </div>
                                    </Card>
                                  </Col>
                                  <Col span={24}>
                                    <Card
                                      bordered={false}
                                      style={{ marginTop: "-1em" }}
                                    >
                                      <div className="f-15 " style={{}}>
                                        {stock.bodyType ? (
                                          <span>
                                            <i
                                              style={{ width: "1.5em" }}
                                              className="fa fa-stock"
                                              aria-hidden="true"
                                            ></i>
                                            {"   "}
                                            {stock.bodyType}

                                            <br />
                                          </span>
                                        ) : null}
                                        {stock.bodyType ? (
                                          <span>
                                            <i
                                              style={{ width: "1.5em" }}
                                              className="fa fa-calendar-o"
                                              aria-hidden="true"
                                            ></i>
                                            {"   "}
                                            {stock.modelYear}
                                            <br />
                                          </span>
                                        ) : null}{" "}
                                        {stock.branch ? (
                                          <span>
                                            <i
                                              style={{ width: "1.5em" }}
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {"   "}
                                            {stock.branch}
                                            <br />
                                          </span>
                                        ) : null}{" "}
                                        {stock.fuelType ? (
                                          <span>
                                            <i
                                              style={{ width: "1.5em" }}
                                              className="fa fa-ils"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {stock.fuelType}
                                            <br />
                                          </span>
                                        ) : null}
                                        {stock.transmission ? (
                                          <span>
                                            <i
                                              style={{ width: "1.5em" }}
                                              className="fa fa-cog"
                                              aria-hidden="true"
                                            ></i>
                                            {stock.transmission}
                                          </span>
                                        ) : null}
                                      </div>
                                    </Card>
                                  </Col>
                                </Row>{" "}
                              </Badge.Ribbon>
                            </div>
                            <div className="col-md-1"></div>
                          </div>
                          <div className="m-t-lg-0">
                            <div
                              style={{
                                textAlign: window.isPhone ? "center" : "",
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingBottom: 20,
                              }}
                              className="banner-item banner-bg-10 color-inher m-t-lg-30"
                            >
                              <h3
                                className="f-weight-300"
                                style={{ marginBottom: "0em" }}
                              >
                                <strong style={{ lineHeight: "1em" }}>
                                  Enquire Now
                                </strong>
                              </h3>
                              <p style={{ margin: "auto" }}>
                                Speak to a Product Specialist today!
                              </p>
                            </div>
                            <div className="bg1-gray-2">
                              <div id="book-service">
                                <Form initialValues={{
                                  remember: true,
                                }} name='wrap'
                                  labelCol={{ flex: "110px" }}
                                  labelAlign='left'
                                  labelWrap
                                  wrapperCol={{ flex: 1 }}
                                  colon={false}
                                  onFinish={this.handleSubmit}
                                  style={{ maxWidth: "80vw", margin: "auto" }}>
                                  <div >
                                    <div className="row">
                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          name='firstName'
                                          rules={[{ required: true, message: false }]}
                                          hasFeedback
                                        >
                                          <StyledInput minLength={3}
                                            placeholder='First Name'
                                            type="text"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                          />
                                        </Form.Item>
                                      </div>

                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          type="text"
                                          name="surname"
                                          rules={[{ required: true, message: false, }]}
                                          hasFeedback
                                        >
                                          <StyledInput minLength={3}
                                            placeholder='Surname'
                                            type="text"
                                            name="surname"
                                            value={this.state.surname}
                                            onChange={this.handleInputChange}
                                          /></Form.Item>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          name="cellPhone"
                                          rules={[
                                            { required: true, message: 'Please enter a cell phone number' },
                                            { min: 10, message: 'Number must have a minimum of 10 digits' },
                                            { max: 10, message: 'Number must have a maximum of 10 digits' }
                                          ]}
                                          hasFeedback
                                        >
                                          <StyledInput
                                            placeholder='Number'
                                            type="number"
                                            name="cellPhone"
                                            value={this.state.cellPhone}
                                            onChange={this.handleInputChange}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div></div>
                                  <div
                                    className="col-sm-4 col-md-4 col-lg-4"
                                    style={{ display: "none" }}
                                  >
                                    <Form.Item
                                      type="text"
                                      name="email"
                                      initialValue={stock.email}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput
                                      /></Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="make"
                                      initialValue={stock.make}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput
                                      /></Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="series"
                                      initialValue={stock.series}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput
                                      /></Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="modelDesc"
                                      initialValue={stock.modelDesc}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput
                                      /></Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="stockNumber"
                                      initialValue={stock.stockNumber}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="price"
                                      initialValue={stock.price}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="branch"
                                      initialValue={stock.branch}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="brand"
                                      initialValue={stock.brand}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="vehicleStockNumber"
                                      initialValue={stock.vehicleStockNumber}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="modelYear"
                                      initialValue={stock.modelYear}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="condition"
                                      initialValue={stock.condition}
                                      rules={[{ required: false, message: false }]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                  </div>
                                  <br />
                                  <div className="col-lg-12 m-t-lg-12 text-center">
                                    <Button
                                      disabled={
                                        this.state.submitDisabled === true
                                          ? true
                                          : false
                                      }
                                      style={{
                                        border: "1px solid #F91700",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        borderRadius: "0.3em",
                                        backgroundColor: "#F91700",
                                        color: "white",
                                        height: "45px",
                                        width: "100%",
                                        paddingLeft: "3em",
                                        paddingRight: "3em",
                                        textShadow: "none",
                                        margin: "auto",
                                        marginTop: "-1em",
                                      }}
                                      htmlType="submit"
                                    >
                                      Submit
                                    </Button>
                                    <p
                                      style={{
                                        color: "black",
                                        //marginBottom: "2.5em",
                                        marginTop: "1em",
                                        fontSize: "13px",
                                        lineHeight: "1.5em",
                                      }}
                                    >
                                      By submitting this form, you agree to our{" "}
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        <Link
                                          style={{ color: "black" }}
                                          to="/terms"
                                        >
                                          Terms of Use
                                        </Link>
                                      </span>{" "}
                                      and{" "}
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        <Link
                                          style={{ color: "black" }}
                                          to="/privacy-policy"
                                        >
                                          Privacy Statement
                                        </Link>
                                      </span>
                                      .<br />
                                      {this.state.errorMessage && <p>Error: {this.state.errorMessage}</p>}
                                    </p>{" "}

                                  </div>
                                </Form>
                              </div>
                            </div>

                            <div className="f-12">Share</div>
                            <br />
                            <a
                              href={generateEmailLink(stock, selectedId)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "grey",
                                padding: "0.8em",
                                paddingTop: "1.3em",
                                marginTop: "1em",
                              }}
                            >
                              <MailOutlined
                                style={{ fontSize: "22px", color: "white" }}
                              />
                            </a>
                            <a
                              href={generateWhatsAppLink(stock, selectedId)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "#25d366",
                                padding: "0.8em",
                                paddingTop: "1.3em",
                                marginLeft: "0.3em",
                                marginTop: "1em",
                              }}
                            >
                              <WhatsAppOutlined
                                style={{ fontSize: "22px", color: "white" }}
                              />
                            </a>
                          </div>




                        </div>
                      </div>
                    </div>{" "}
                    <div className="row m-t-lg-50 m-b-lg-50 desktop-view">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <Badge.Ribbon text={stock.condition} color="red">
                          <Row
                            gutter={16}
                            style={{
                              //padding: "2em",
                              boxShadow: "2px 2px 10px 2px #ecececd7",
                              borderRadius: "1.5em",
                            }}
                          >
                            <Col span={10}>
                              <Card bordered={false}>
                                <h4
                                  style={{
                                    height: "6em",
                                    paddingBottom: "0em",
                                    paddingRight: "0.2em",
                                    borderRight: "1px solid lightgrey",
                                  }}
                                  className="product-name m-t-lg-15"
                                //style={{ marginBottom: "0em", marginTop: "-1em" }}
                                >
                                  <span className="f-22">
                                    {stock.make} {stock.series} <br />
                                    <p className="f-16">{stock.modelDesc}</p>
                                  </span>
                                </h4>{" "}
                              </Card>
                            </Col>
                            <Col span={7}>
                              <Card bordered={false}>
                                <div
                                  style={{
                                    borderRight: "1px solid lightgrey",
                                    height: "8.6em",
                                  }}
                                >
                                  <div className="f-12 m-t-lg-5 m-b-lg-5 m-t-lg-15 mn-cl">
                                    Price
                                  </div>
                                  <div className="f-40 m-b-lg-5 m-t-lg-0 mn-cl">
                                    <CurrencyFormat
                                      value={stock.price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"R"}
                                      //format='R ### ### ### ###'
                                      renderText={value => (
                                        <div style={{ lineHeight: 1 }}>
                                          {value}
                                        </div>
                                      )}
                                    />
                                  </div>
                                  {/*<p className='f-20 f-weight-500 m-b-lg-0'>
                                <CurrencyFormat
                                  value={3650}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"R"}
                                  suffix={" pm"}
                                  //format='R #### #### pm*'
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </p>{" "}*/}
                                  {/*<div className='f-15 m-t-lg-0 m-b-lg-0 m-t-lg-0'>
                                RATE CALCULATION
                              </div>*/}
                                </div>
                              </Card>
                            </Col>
                            <Col span={7}>
                              <Card bordered={false}>
                                <div
                                  className="f-15 m-t-lg-0 m-b-lg-0 m-t-lg-15"
                                  style={{ height: "6em" }}
                                >
                                  {stock.bodyType ? (
                                    <span>
                                      <i
                                        style={{ width: "1.5em" }}
                                        className="fa fa-stock"
                                        aria-hidden="true"
                                      ></i>
                                      {"   "}
                                      {stock.bodyType}

                                      <br />
                                    </span>
                                  ) : null}
                                  {stock.modelYear ? (
                                    <span>
                                      <i
                                        style={{ width: "1.5em" }}
                                        className="fa fa-calendar-o"
                                        aria-hidden="true"
                                      ></i>
                                      {"   "}
                                      {stock.modelYear}
                                      <br />
                                    </span>
                                  ) : null}{" "}
                                  {stock.branch ? (
                                    <span>
                                      <i
                                        style={{ width: "1.5em" }}
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {"   "}
                                      {stock.branch}
                                      <br />
                                    </span>
                                  ) : null}{" "}
                                  {stock.fuelType ? (
                                    <span>
                                      <i
                                        style={{ width: "1.5em" }}
                                        className="fa fa-ils"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {stock.fuelType}
                                      <br />
                                    </span>
                                  ) : null}
                                  {stock.transmission ? (
                                    <span>
                                      <i
                                        style={{ width: "1.5em" }}
                                        className="fa fa-cog"
                                        aria-hidden="true"
                                      ></i>
                                      {stock.transmission}
                                      <br />
                                    </span>
                                  ) : null}
                                  {stock.mileage ? (
                                    <span>
                                      <i
                                        className="fa fa-road"
                                        style={{ width: "1.5em" }}
                                      ></i>
                                      <CurrencyFormat
                                        value={stock.mileage}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={" km"}
                                        //format='R### ### ### ###'
                                        renderText={value => (
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            {value}
                                          </div>
                                        )}
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              </Card>
                            </Col>
                          </Row>{" "}
                        </Badge.Ribbon>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div
                      className="row m-t-lg-30"
                      style={{ marginBottom: window.isPhone ? "0em" : "50px" }}
                    >
                      <div className="col-md-8">
                        <Tabs defaultActiveKey="1" onChange={callback}>
                          <TabPane tab="Overview" key="1">
                            <div className="m-b-lg-30">
                              <div className="heading-1 m-t-lg-20">
                                <h3 className="f-18">OverView</h3>
                              </div>
                              <div className="m-b-lg-30 bg-gray-fa bg1-gray-2 p-lg-30 p-xs-15">
                                <p className="color1-9">{stock.comments}</p>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tab="General" key="2">
                            <div className="heading-1 m-t-lg-20">
                              <h3 className="f-18">General</h3>
                            </div>
                            <ul className="product_para-1">
                              {stock.make ? (
                                <li>
                                  <span>Make :</span>
                                  {stock.make}
                                </li>
                              ) : null}
                              {stock.series ? (
                                <li>
                                  <span>Model :</span>
                                  {stock.series}
                                </li>
                              ) : null}
                              {stock.bodyType ? (
                                <li>
                                  <span>Body :</span>
                                  {stock.bodyType}
                                </li>
                              ) : null}
                              {stock.fuelType ? (
                                <li>
                                  <span>Fuel :</span>
                                  {stock.fuelType}
                                </li>
                              ) : null}
                              {stock.modelDesc ? (
                                <li>
                                  <span>Engine :</span>
                                  {stock.modelDesc}
                                </li>
                              ) : null}
                              {stock.transmission ? (
                                <li>
                                  <span>Transmission :</span>
                                  {stock.transmission}
                                </li>
                              ) : null}
                              {stock.extColour ? (
                                <li>
                                  <span>Color :</span>
                                  {stock.extColour}
                                </li>
                              ) : null}
                              {/* {mileage ? (
                            <li>
                              <span>Mileage :</span>
                              {mileage}
                            </li>
                          ) : null} */}
                            </ul>
                          </TabPane>
                          <TabPane tab="Engine" key="3">
                            <div className="heading-1 m-t-lg-20">
                              <h3 className="f-18">Engine</h3>
                            </div>
                            <ul className="product_para-1">
                              {stock.engineSize ? (
                                <li>
                                  <span>Engine Size :</span>
                                  {stock.engineSize}
                                </li>
                              ) : null}
                              {stock.vehicleDrive ? (
                                <li>
                                  <span>Vehicle Drive :</span>
                                  {stock.vehicleDrive}
                                </li>
                              ) : null}
                              {stock.axles ? (
                                <li>
                                  <span>Axles :</span>
                                  {stock.axles}
                                </li>
                              ) : null}
                              {stock.cylinders ? (
                                <li>
                                  <span>Cylinders :</span>
                                  {stock.cylinders}
                                </li>
                              ) : null}
                              {stock.fuelType ? (
                                <li>
                                  <span>Fuel type :</span>
                                  {stock.fuelType}
                                </li>
                              ) : null}
                              {stock.fuelTankSize ? (
                                <li>
                                  <span>Fuel capacity :</span>
                                  {stock.fuelTankSize}
                                </li>
                              ) : null}
                            </ul>
                          </TabPane>
                          <TabPane tab="Comfort" key="4">
                            <div className="heading-1 m-t-lg-20">
                              <h3 className="f-18">Comfort</h3>
                            </div>
                            <ul className="product_para-1">
                              {stock.passengers ? (
                                <li>
                                  <span>Seats :</span>
                                  {stock.passengers}
                                </li>
                              ) : null}
                              {stock.doors ? (
                                <li>
                                  <span>No of doors :</span>
                                  {stock.doors}
                                </li>
                              ) : null}
                            </ul>
                          </TabPane>
                          <TabPane tab="Extras And Accessories" key="5">
                            <div className="heading-1 m-t-lg-20">
                              <h3 className="f-18">Extras And Accessories</h3>
                            </div>
                            <div className="desktop-view">
                              <Row>
                                {stock.degreeCamera === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      360 Degree Camera
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.fourWheelDrive === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      4WD
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.abs === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      ABS
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.driverAssistance === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Driver Assistance System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.airBags === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Airbags
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.aircon === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Aircon
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.alarm === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Alarm
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.mobileCarPlay === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Apple CarPlay / Android Auto
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}

                                {stock.automaticLights === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Automatic Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.automaticWipers === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Automatic Wipers
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.autonomousBraking === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Auto Emergency Braking
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.blindSpotMonitoring === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Blind Spot Monitoring
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.bluetooth === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Bluetooth
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.brakeAssist === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Brake Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.climateControl === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Climate Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.cruiseControl === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Cruise Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.daytimeLights === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Daytime Running Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.electricParkingBrake === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electric Parking Brake
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.elctricWindows === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electric Windows
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.electronicStability === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electronic Stability System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.collisionWarning === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Forward Collision Warning
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.headDisplay === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Head-up Display
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.heatedSeats === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Heated / Ventilated Seats
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.hillAssist === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Hill Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.hillControl === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Hill Descent Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.keylessEntry === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Keyless Entry
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.laneWarning === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Lane Departure Warning
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.laneAssist === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Lane Keeping Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.leatherSeats === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Leather Seats
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.ledLights === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      LED Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.navigation === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Navigation
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.paddleShift === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Paddle Shift
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.parkControl === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Park Distance Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.powerBoot === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Power Boot
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.powerSeat === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Power Seat(s)
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.remoteStart === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Remote Start
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.reverseCamera === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Reverse Camera
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.sunroof === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Sunroof
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.touchScreen === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Touch Screen System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.towbar === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Towbar
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.signRecognition === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Traffic Sign Recognition
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.tyreMonitor === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Tyre Pressure Monitor
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.usbPort === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      USB Port(s)
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.voiceRecognition === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Voice Recognition
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.warranty === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Warranty / Service Plan
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.wirelessCharging === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Wireless Charging
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.xenonLights === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Xenon Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                              </Row>
                            </div>
                            <div className="mobile-view">
                              <Row>
                                {stock.degreeCamera === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      360 Degree Camera
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.fourWheelDrive === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      4WD
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.abs === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      ABS
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.driverAssistance === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Driver Assistance System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.airBags === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Airbags
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.aircon === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Aircon
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.alarm === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Alarm
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.mobileCarPlay === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Apple CarPlay / Android Auto
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}

                                {stock.automaticLights === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Automatic Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.automaticWipers === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Automatic Wipers
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.autonomousBraking === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Auto Emergency Braking
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.blindSpotMonitoring === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Blind Spot Monitoring
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.bluetooth === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Bluetooth
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.brakeAssist === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Brake Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.climateControl === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Climate Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.cruiseControl === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Cruise Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.daytimeLights === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Daytime Running Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.electricParkingBrake === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electric Parking Brake
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.elctricWindows === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electric Windows
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.electronicStability === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Electronic Stability System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.collisionWarning === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Forward Collision Warning
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.headDisplay === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Head-up Display
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.heatedSeats === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Heated / Ventilated Seats
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.hillAssist === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Hill Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.hillControl === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Hill Descent Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.keylessEntry === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Keyless Entry
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.laneWarning === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Lane Departure Warning
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.laneAssist === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Lane Keeping Assist
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.leatherSeats === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Leather Seats
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.ledLights === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      LED Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.navigation === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Navigation
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.paddleShift === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Paddle Shift
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.parkControl === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Park Distance Control
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.powerBoot === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Power Boot
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.powerSeat === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Power Seat(s)
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.remoteStart === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Remote Start
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.reverseCamera === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Reverse Camera
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.sunroof === true ? (
                                  <Col span={8}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Sunroof
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.touchScreen === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Touch Screen System
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.towbar === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Towbar
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.signRecognition === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Traffic Sign Recognition
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.tyreMonitor === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Tyre Pressure Monitor
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.usbPort === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      USB Port(s)
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.voiceRecognition === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Voice Recognition
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.warranty === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Warranty / Service Plan
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.wirelessCharging === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Wireless Charging
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                                {stock.xenonLights === true ? (
                                  <Col span={12}>
                                    <StyledCheckbox
                                      checked={true}
                                      onChange={onChange}
                                    >
                                      Xenon Lights
                                    </StyledCheckbox>
                                  </Col>
                                ) : null}
                              </Row>
                            </div>
                          </TabPane>{" "}
                          {/* {window.isPhone ? null : (
                            <TabPane tab="Finance Calculator" key="6">
                              <div className="col-md-5 col-lg-12">
                                <div className="heading-1 m-t-lg-20">
                                  <h3 className="f-18">Finance Calculator</h3>
                                  <FinanceBox />{" "}
                                  <Collapse ghost>
                                    <Panel header="Disclaimer" key="1">
                                      <p>{text}</p>
                                    </Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </TabPane>
                          )} */}
                        </Tabs>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 m-t-lg-15">
                        <div className="heading-1">
                          <h3>
                            <i className="fa fa-info-circle"></i>Dealer Infomation
                          </h3>
                        </div>
                        <div
                          className="bg-gray-fa bg1-gray-2 p-lg-15 text-center m-b-lg-20 display-block"
                        >
                          {stock.branchImage ? (
                            <img
                              style={{ width: "18em" }}
                              src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.branchImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ width: "18em" }}
                              src={
                                stock.brand === "Mega Theron Group"
                                  ? `${process.env.PUBLIC_URL}/images/logo.png`
                                  : stock.brand === "Mega Theron Traders"
                                    ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                    : stock.brand === "Kia"
                                      ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                      : stock.brand === "Mahindra"
                                        ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                        : stock.brand === "Chery"
                                          ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                          : stock.brand === "Gwm"
                                            ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                            : stock.brand === "Haval"
                                              ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                              : stock.brand === "JAC"
                                                ? `${process.env.PUBLIC_URL}/images/jac-image.png`
                                                : `${process.env.PUBLIC_URL}/images/logo.png`
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <h4 className="p-t-lg-0">
                          {stock.brand || stock.branch ? (
                            <span>
                              {stock.brand}, {stock.branch}
                            </span>
                          ) : null}
                        </h4>
                        <div className="clearfix"></div>
                        <ul className="list-default m-t-lg-0">
                          {/*<li>
                        <i className="fa fa-user-circle-o m-r-lg-10  icon"></i>Mr.
                        Janhn
                      </li>*/}
                          {stock.number ? (
                            <li>
                              {" "}
                              <a href={"tel:" + stock.number}>
                                <i className="fa fa-phone m-r-lg-10 icon"></i>
                                {stock.number}
                              </a>
                            </li>
                          ) : null}
                          {stock.email ? (
                            <li>
                              <a href={"mailto:" + stock.email}>
                                <i className="fa fa-envelope-o m-r-lg-10 icon"></i>
                                {stock.email}
                              </a>
                            </li>
                          ) : null}
                          {/*{address ?
                      <li>
                        <i className='fa fa-home m-r-lg-10 icon'></i>{address}
                      </li>: null}*/}
                          {/*{number ?
                      <li className='view-map'>
                        <i className='fa fa-map-marker m-r-lg-10 icon'></i>Click to
                        view Map
                      </li>
                      : null}*/}
                          {/*{website ?
                      <li>
                        <a href='https://www.megatheron.co.za/'>
                          <i className='fa fa-globe m-r-lg-10 icon'></i>
                          {website}
                        </a>
                      </li>: null}*/}
                        </ul>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="m-t-lg-0">
                          <div className="banner-item banner-2x banner-bg-10 color-inher m-t-lg-30 p-lg-30">
                            <h3 className="f-weight-300">
                              <strong style={{ lineHeight: "1em" }}>
                                Make Vehicle Enquiry
                              </strong>
                            </h3>
                            <p style={{ margin: "auto" }}>
                              Speak to our stock specialists, book your test drive
                              or viewing today.
                            </p>
                          </div>
                          <div className="bg1-gray-2">
                            <div id="book-service">

                              <Form initialValues={{
                                remember: true,
                              }} name='wrap'
                                labelCol={{ flex: "110px" }}
                                labelAlign='left'
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                onFinish={this.handleSubmit}
                                style={{ maxWidth: "80vw", margin: "auto" }}>
                                <div >
                                  <div className="row">
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                      <Form.Item
                                        name='firstName'
                                        rules={[{ required: true, message: false }]}
                                        hasFeedback
                                      >
                                        <StyledInput minLength={3}
                                          placeholder='First Name'
                                          type="text"
                                          name="firstName"
                                          value={this.state.firstName}
                                          onChange={this.handleInputChange}
                                        />
                                      </Form.Item>
                                    </div>

                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                      <Form.Item
                                        type="text"
                                        name="surname"
                                        rules={[{ required: true, message: false, }]}
                                        hasFeedback
                                      >
                                        <StyledInput minLength={3}
                                          placeholder='Surname'
                                          type="text"
                                          name="surname"
                                          value={this.state.surname}
                                          onChange={this.handleInputChange}
                                        /></Form.Item>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                      <Form.Item
                                        name="cellPhone"
                                        rules={[
                                          { required: true, message: 'Please enter a cell phone number' },
                                          { min: 10, message: 'Number must have a minimum of 10 digits' },
                                          { max: 10, message: 'Number must have a maximum of 10 digits' }
                                        ]}
                                        hasFeedback
                                      >
                                        <StyledInput
                                          placeholder='Number'
                                          type="number"
                                          name="cellPhone"
                                          value={this.state.cellPhone}
                                          onChange={this.handleInputChange}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div></div>
                                <div
                                  className="col-sm-4 col-md-4 col-lg-4"
                                  style={{ display: "none" }}
                                >
                                  <Form.Item
                                    type="text"
                                    name="make"
                                    initialValue={stock.make}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput
                                    /></Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="email"
                                    initialValue={stock.email}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput
                                    /></Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="series"
                                    initialValue={stock.series}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput
                                    /></Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="modelDesc"
                                    initialValue={stock.modelDesc}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput
                                    /></Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="stockNumber"
                                    initialValue={stock.stockNumber}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="price"
                                    initialValue={stock.price}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="branch"
                                    initialValue={stock.branch}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="brand"
                                    initialValue={stock.brand}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="vehicleStockNumber"
                                    initialValue={stock.vehicleStockNumber}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="modelYear"
                                    initialValue={stock.modelYear}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                  <Form.Item
                                    type="text"
                                    name="condition"
                                    initialValue={stock.condition}
                                    rules={[{ required: false, message: false }]}
                                    hasFeedback
                                  >
                                    <StyledInput />
                                  </Form.Item>
                                </div>
                                <br />
                                <div className="col-lg-12 m-t-lg-12 text-center">
                                  <Button disabled={this.state.submitDisabled === true ? true : false} style={{
                                    fontFamily: "Mulish",
                                    border: "1px solid #F91700",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "0.3em",
                                    backgroundColor: "#F91700",
                                    color: "white",
                                    height: "55px",
                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    // marginTop: "2em",
                                  }} htmlType='submit'
                                  >Submit</Button>
                                  <p
                                    style={{
                                      color: "black",
                                      //marginBottom: "2.5em",
                                      marginTop: "1em",
                                      fontSize: "13px",
                                      lineHeight: "1.5em",
                                    }}
                                  >
                                    By submitting this form, you agree to our{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "black" }}
                                        to="/terms"
                                      >
                                        Terms of Use
                                      </Link>
                                    </span>{" "}
                                    and{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "black" }}
                                        to="/privacy-policy"
                                      >
                                        Privacy Statement
                                      </Link>
                                    </span>
                                    .<br />
                                    {this.state.errorMessage && <p>Error: {this.state.errorMessage}</p>}
                                  </p>{" "}

                                </div>
                              </Form>


                            </div>



                          </div>
                        </div>{" "}
                      </div>
                    </div>
                    {/*<div className='product product-grid product-grid-2 stock m-b-lg-15'>
                  <div className='heading'>
                    <h3>Other Stock</h3>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                      <div className='product-item hover-img'>
                        <a className='product-img'>
                          <i
                            className='fa fa-heart'
                            style={{
                              background: "#ecececbe",
                              borderRadius: "0em 0em 0em 0.5em",
                              padding: "1em",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              background: "#FE5F5C",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            New
                          </span>
                          <span
                            style={{
                              background: "#0C65CE",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 70,
                              top: 155,
                              color: "white",
                            }}
                          >
                            2015
                          </span>
                          <span
                            style={{
                              background: "#00000093",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              left: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            62 000km
                          </span>
                          <img
                              src={`${process.env.PUBLIC_URL}/images/b-img-13.jpg`}
                            alt=''
                          />
                        </a>
                        <div className='product-caption'>
                          <h4
                            className='product-name'
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <a>
                              BMW <br />
                              <p className='f-15'>218i M SPORT ACTIVE TOURER</p>
                            </a>
                          </h4>{" "}
                          <div className='product-name'>
                            <span
                              className='f-15 m-t-lg-5'
                              style={{
                                float: "left",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              <img
                                  src={`${process.env.PUBLIC_URL}/images/mt-branch.png`}
                                alt=''
                                style={{
                                  width: "6em",
                                  position: "absolute",
                                  marginTop: "-1.9em",
                                }}
                              />{" "}
                              Klerksdorp
                            </span>
                            <span
                              className='f-20 m-t-lg-0 mn-cl'
                              style={{ float: "right" }}
                            >
                              R259 950
                            </span>
                          </div>
                          <ul className='static-caption m-t-lg-20'>
                            <li>
                              <i className='fa fa-clock-o'></i>
                            
                              Petrol
                            </li>
                            <li>
                              <i className='fa fa-tachometer'></i>
                              Automatic
                            </li>
                            <li>
                              <i className='fa fa-road'></i>13,000
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                      <div className='product-item hover-img'>
                        <a className='product-img'>
                          <i
                            className='fa fa-heart'
                            style={{
                              background: "#ecececbe",
                              borderRadius: "0em 0em 0em 0.5em",
                              padding: "1em",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              color: "grey",
                            }}
                          />
                          <span
                            style={{
                              background: "#FE5F5C",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            New
                          </span>
                          <span
                            style={{
                              background: "#0C65CE",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 70,
                              top: 155,
                              color: "white",
                            }}
                          >
                            2015
                          </span>
                          <span
                            style={{
                              background: "#00000093",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              left: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            62 000km
                          </span>
                          <img
                              src={`${process.env.PUBLIC_URL}/images/b-img-3.jpg`}
                            alt=''
                          />
                        </a>
                        <div className='product-caption'>
                          <h4
                            className='product-name'
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <a>
                              MAZDA <br />
                              <p className='f-15'>218i M SPORT ACTIVE TOURER</p>
                            </a>
                          </h4>{" "}
                          <div className='product-name'>
                            <span
                              className='f-15 m-t-lg-5'
                              style={{
                                float: "left",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              <img
                                  src={`${process.env.PUBLIC_URL}/images/kia-branch.png`}
                                alt=''
                                style={{
                                  width: "6em",
                                  position: "absolute",
                                  marginTop: "-1.9em",
                                }}
                              />{" "}
                              Klerksdorp
                            </span>
                            <span
                              className='f-20 m-t-lg-0 mn-cl'
                              style={{ float: "right" }}
                            >
                              R259 950
                            </span>
                          </div>
                          <ul className='static-caption m-t-lg-20'>
                            <li>
                              <i className='fa fa-clock-o'></i>
                            
                              Petrol
                            </li>
                            <li>
                              <i className='fa fa-tachometer'></i>
                              Automatic
                            </li>
                            <li>
                              <i className='fa fa-road'></i>13,000
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                      <div className='product-item hover-img'>
                        <a className='product-img'>
                          <i
                            className='fa fa-heart'
                            style={{
                              background: "#ecececbe",
                              borderRadius: "0em 0em 0em 0.5em",
                              padding: "1em",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              color: "grey",
                            }}
                          />
                          <span
                            style={{
                              background: "#FE5F5C",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            New
                          </span>
                          <span
                            style={{
                              background: "#0C65CE",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 70,
                              top: 155,
                              color: "white",
                            }}
                          >
                            2015
                          </span>
                          <span
                            style={{
                              background: "#00000093",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              left: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            62 000km
                          </span>
                          <img
                              src={`${process.env.PUBLIC_URL}/images/b-img-2.jpg`}
                            alt=''
                          />
                        </a>
                        <div className='product-caption'>
                          <h4
                            className='product-name'
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <a>
                              DODGE <br />
                              <p className='f-15'>218i M SPORT ACTIVE TOURER</p>
                            </a>
                          </h4>{" "}
                          <div className='product-name'>
                            <span
                              className='f-15 m-t-lg-5'
                              style={{
                                float: "left",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              <img
                                  src={`${process.env.PUBLIC_URL}/images/mt-branch.png`}
                                alt=''
                                style={{
                                  width: "6em",
                                  position: "absolute",
                                  marginTop: "-1.9em",
                                }}
                              />{" "}
                              Klerksdorp
                            </span>
                            <span
                              className='f-20 m-t-lg-0 mn-cl'
                              style={{ float: "right" }}
                            >
                              R259 950
                            </span>
                          </div>
                          <ul className='static-caption m-t-lg-20'>
                            <li>
                              <i className='fa fa-clock-o'></i>
                        
                              Petrol
                            </li>
                            <li>
                              <i className='fa fa-tachometer'></i>
                              Automatic
                            </li>
                            <li>
                              <i className='fa fa-road'></i>13,000
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                      <div className='product-item hover-img'>
                        <a className='product-img'>
                          <i
                            className='fa fa-heart'
                            style={{
                              background: "#ecececbe",
                              borderRadius: "0em 0em 0em 0.5em",
                              padding: "1em",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              background: "#FE5F5C",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            New
                          </span>
                          <span
                            style={{
                              background: "#0C65CE",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 70,
                              top: 155,
                              color: "white",
                            }}
                          >
                            2015
                          </span>
                          <span
                            style={{
                              background: "#00000093",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              left: 10,
                              top: 155,
                              color: "white",
                            }}
                          >
                            62 000km
                          </span>
                          <img
                              src={`${process.env.PUBLIC_URL}/images/b-img-4.jpg`}
                            alt=''
                          />
                        </a>
                        <div className='product-caption'>
                          <h4
                            className='product-name'
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <a>
                              HYUNDAI <br />
                              <p className='f-15'>218i M SPORT ACTIVE TOURER</p>
                            </a>
                          </h4>{" "}
                          <div className='product-name'>
                            <span
                              className='f-15 m-t-lg-5'
                              style={{
                                float: "left",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              <img
                                  src={`${process.env.PUBLIC_URL}/images/kia-branch.png`}
                                alt=''
                                style={{
                                  width: "6em",
                                  position: "absolute",
                                  marginTop: "-1.9em",
                                }}
                              />{" "}
                              Klerksdorp
                            </span>
                            <span
                              className='f-20 m-t-lg-0 mn-cl'
                              style={{ float: "right" }}
                            >
                              R259 950
                            </span>
                          </div>
                          <ul className='static-caption m-t-lg-20'>
                            <li>
                              <i className='fa fa-clock-o'></i>
                           
                              Petrol
                            </li>
                            <li>
                              <i className='fa fa-tachometer'></i>
                              Automatic
                            </li>
                            <li>
                              <i className='fa fa-road'></i>13,000
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
                  </section>{" "}
                  <Modal
                    visible={this.state.visible}
                    style={{ textAlign: "center" }}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="Logo"
                      style={{ width: "65%", margin: "auto" }}
                    />
                    <h3
                      style={{
                        marginTop: "1em",
                        color: "black",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      Thank you for contacting
                      <br />
                      <span style={{ color: "rgb(214, 54, 41)" }}>
                        The Mega Theron Group.
                      </span>{" "}
                      <br />
                      <br />
                      <span style={{ color: "black ", fontSize: "17px" }}>
                        Our vehicle specialist will be in touch shortly.
                      </span>{" "}
                      <br /> <br />
                      <span style={{ color: "rgb(214, 54, 41)", fontSize: "14px" }}>
                        {this.state.leadReference && <p>Your Reference Code: <br />{this.state.leadReference}</p>}

                      </span>{" "}
                      <br />
                    </h3>{" "}
                    <br />
                  </Modal>
                </div>{" "}
              </div>
            ) : null;
          })}
        </div>
      </Spin>
    );
  }
}
function mapStateToProps({ selectedStock }) {
  return { selectedStock };
}

export default connect(mapStateToProps)(withRouter(StockDetailsKlerksdorp));