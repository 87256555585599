import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { range } from "lodash";
import StockList from "./StockListNew";
import { fetchFiltredStock, turnOffHomefilter } from "../../redux/actions";
import { connect } from "react-redux";
import $ from "jquery";
import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined, LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class StockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageSize: 24,
      priceSort: "High to Low",
      displayType: "cards",
      fetchedStock: props.fetchedStock,
      renderedStock: {},
      buttonSearchClicked: false,
      years: [],
      Year: "",
      Brand: "",
      ModelRange: "",
      currentSort: "down",
      mileage: "",
      NewUsed: "",
      BodyType: "",
      Transmission: "",
      resetValue: false,
      vehicleDrive: "",
      //features: "",
      DealershipName: "",
      Colour: "",
      FuelType: "",
      onSale: undefined,
      Model: "",
      webVisible: "",
      checked: true,
      priceRange: "R5,000 - R999,000",
      mileageRange: "1,000km -  300,000km",
      yearRange: "2000 -  2025",
    };
    this.setCarYear = this.setCarYear.bind(this);
    this.setCarMake = this.setCarMake.bind(this);
    this.setCarModel = this.setCarModel.bind(this);
    this.setCarEngineCapacity = this.setCarEngineCapacity.bind(this);
    this.setCarMileage = this.setCarMileage.bind(this);
    this.setCarCondition = this.setCarCondition.bind(this);
    this.setCarBody = this.setCarBody.bind(this);
    this.setCarTransmission = this.setCarTransmission.bind(this);
    this.setCarDriveTrain = this.setCarDriveTrain.bind(this);
    this.setCarFeatures = this.setCarFeatures.bind(this);
    this.setCarBranch = this.setCarBranch.bind(this);
    this.setCarColour = this.setCarColour.bind(this);
    this.setCarFuelType = this.setCarFuelType.bind(this);
    this.setCarOnSale = this.setCarOnSale.bind(this);
    this.renderStock = this.renderStock.bind(this);
  }
  componentWillMount = () => {
    fetch("https://www.vcsappcloud.com/mtgroup/api/evolve/findyears", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async res => {
      const result = await res.json();
      this.setState({ years: result.years });
    });
  };
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };
  resetValue = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  resetAll = () => {
    this.setState({ resetValue: !this.state.resetValue });
  };
  onChange = e => {
    console.log("checked = ", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
  };
  buttonSearchClicked() {
    if (this.state !== null) {
      var {
        Year,
        Brand,
        ModelRange,
        Model,
        Mileage,
        NewUsed,
        BodyType,
        Transmission,
        vehicleDrive,
        Features,
        DealershipName,
        Colour,
        FuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      } = this.state;
      var filter = {};
      priceRange = $("#priceslider").val();
      mileageRange = $("#mileageslider").val();
      yearRange = $("#yearslider").val();

      filter = {
        Year,
        Brand,
        ModelRange,
        Model,
        Mileage,
        NewUsed,
        BodyType,
        Transmission,
        vehicleDrive,
        Features,
        DealershipName,
        Colour,
        FuelType,
        onSale,
        priceRange,
        mileageRange,
        yearRange,
      };
      this.props.fetchFiltredStock(filter);
      this.setState({ mileageRange });
      this.setState({ priceRange });
      this.setState({ yearRange });
    }
    this.setState({ buttonSearchClicked: true });
    this.props.turnOffHomefilter();
  }
  setCarYear = value => {
    this.setState({ Year: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    (onSale = onSale),
      (filter = {
        Brand,
        Year: value,
        ModelRange,
        Model,
        Mileage,
        NewUsed,
        BodyType,
        Transmission,
        vehicleDrive,
        Features,
        DealershipName,
        Colour,
        FuelType,
        priceRange,
        mileageRange,
        yearRange,
        onSale,
      });
    //this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
    //this.props.turnOffHomefilter();
  };

  setCarMake(value) {
    this.setState({ Brand: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand: value,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarModel(value) {
    this.setState({ ModelRange: value });
    var {
      priceRange,
      yearRange,
      mileageRange,
      Year,
      Brand,

      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;

    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange: value,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      yearRange,
      mileageRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarEngineCapacity(value) {
    this.setState({ Model: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,

      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;

    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model: value,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      mileage: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarCondition(value) {
    this.setState({ NewUsed: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,

      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;

    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed: value,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarMileage(value) {
    this.setState({ mileage: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,

      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;

    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      mileage: value,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBody(value) {
    this.setState({ BodyType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,

      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;

    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType: value,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarTransmission(value) {
    this.setState({ Transmission: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,

      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;

    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission: value,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarDriveTrain = (value) => {
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      Features,

      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;

    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;

    Features = Features;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model: value,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      Features,

      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };


    filter.Model = this.props.fetchedStock
      .filter(stock => stock.Model[0].includes(value))
      .map(stock => stock.Model[0]);


    this.props.fetchFiltredStock(filter);
    this.setState({
      Model: value,
      mileageRange,
      priceRange,
      yearRange,
      buttonSearchClicked: true,
    });
  };
  setCarFeatures(value) {
    this.setState({ features: value });
    var { priceRange } = this.state;
    var { mileageRange } = this.state;
    var { yearRange } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    filter = {
      features: value,
      priceRange,
      mileageRange,
      yearRange,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarBranch(value) {
    this.setState({ DealershipName: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,

      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName: value,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarColour(value) {
    this.setState({ Colour: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,

      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour: value,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }

  setCarFuelType(value) {
    this.setState({ FuelType: value });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType: value,
      priceRange,
      mileageRange,
      yearRange,
      onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setCarOnSale(value) {
    this.setState({ onSale: !this.state.onSale });
    var {
      priceRange,
      mileageRange,
      yearRange,
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
    } = this.state;
    var filter = {};
    priceRange = $("#priceslider").val();
    mileageRange = $("#mileageslider").val();
    yearRange = $("#yearslider").val();
    Year = Year;
    Brand = Brand;
    ModelRange = ModelRange;
    Model = Model;
    Mileage = Mileage;
    NewUsed = NewUsed;
    BodyType = BodyType;
    Transmission = Transmission;
    vehicleDrive = vehicleDrive;
    Features = Features;
    DealershipName = DealershipName;
    Colour = Colour;
    FuelType = FuelType;
    onSale = onSale;
    filter = {
      Brand,
      Year,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      priceRange,
      mileageRange,
      yearRange,
      onSale: !this.state.onSale,
    };
    this.props.fetchFiltredStock(filter);
    this.setState({ mileageRange });
    this.setState({ priceRange });
    this.setState({ yearRange });
    this.setState({ buttonSearchClicked: true });
  }
  setActivePage(activePage) {
    document.documentElement.scrollTop = 0;
    this.setState({ activePage: activePage });
  }
  setPageSize(size) {
    this.setState({ activePage: 1, pageSize: size });
  }
  setPriceSort(stock) {
    stock.sort((a, b) => (a.price > b.price ? 1 : -1));
  }
  setDisplayType(displayType) {
    this.setState({ displayType });
  }

  getallStock() {
    this.props.fetchFiltredStock({});
  }
  viewAllStock() {
    window.location.reload();
    //document.documentElement.scrollTop = 0;
    this.props.fetchFiltredStock({});
  }
  onSortChangeFromLow = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "up",
    });
  };
  onSortChangeFromHigh = () => {
    const { currentSort } = this.state;
    //let nextSort;

    //if (currentSort === "down") nextSort = "up";
    //else if (currentSort === "up") nextSort = "default";
    //else if (currentSort === "default") nextSort = "down";

    this.setState({
      currentSort: "down",
    });
  };
  renderStock() {
    const { currentSort } = this.state;
    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => a.price - b.price,
      },
      down: {
        class: "sort-down",
        fn: (a, b) => b.price - a.price,
      },
      default: {
        class: "sort",
        //fn: (a, b) => a,
        fn: (a, b) => a.price - b.price,
      },
    };
    var { buttonSearchClicked, pageSize, priceSort, activePage, displayType } =
      this.state;
    var { fetchedStock, stockList, homefilterActivated } = this.props;
    var stock =
      fetchedStock.length !== 0
        ? fetchedStock.filter(
          stock => stock.NewUsed.includes("NEW")
        )
        : stockList;

    /**this NewUsed is if the user search for something from the  search button in stock cntainer and no results are found
     * or enters to stock listing throught the home search button and no results found */
    if (
      fetchedStock.length === 0 &&
      (buttonSearchClicked || homefilterActivated)
    ) {
      // Display loading message when no data is available
      return (
        <div className="banner-item banner-2x no-bg ">
          <h2 className="f-weight-300">
            <i className="fa fa-search m-r-lg-10"> </i>No RESULTS
          </h2>
          <a
            className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
            onClick={() => this.viewAllStock()}
          >
            View all stock
          </a>
        </div>
      );
    } else if (fetchedStock.length === 0 && !buttonSearchClicked && !homefilterActivated) {
      // Display a different message when no search or filters have been applied
      return (
        <div className="banner-item banner-2x no-bg ">
          <LoadingOutlined
            style={{
              fontSize: 34,
              marginBottom: "0.7em"
            }}
            spin
          />
          <h2 className="f-weight-300">
            Preparing Vehicles...
          </h2>
        </div>
      );
    } else if (this.state.loading) {
      // Display loading indicator while data is being fetched
      return (
        <div className="loading-indicator">
          Preparing Vehicles...
        </div>
      );
    } else {
      // Display the stock list when data is available and loading is complete
      return (
        <StockList
          displayType={displayType}
          stocklist={stock
            .sort(sortTypes[currentSort].fn)
            .slice(
              (activePage - 1) * pageSize,
              (activePage - 1) * pageSize + pageSize
            )}
        />
      );
    }
  }
  scrollOnclick() {
    window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    var { fetchedStock, stockList, filters, homefilterActivated } = this.props;
    var { pageSize, priceSort, buttonSearchClicked } = this.state;
    const {
      Year,
      Brand,
      ModelRange,
      Model,
      Mileage,
      NewUsed,
      BodyType,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
      priceRange,
      mileageRange,
      yearRange,
    } = filters;
    const duplicateCheck = [];
    var stock = fetchedStock.length !== 0 ? fetchedStock : stockList;
    /**stock list will be empty if a search returns no result */
    if (fetchedStock.length === 0 && buttonSearchClicked) stock = {};
    /**returns a number to know the number of pages */
    const table = range(1, Math.ceil(fetchedStock.filter(
      stock => stock.NewUsed.includes("NEW")
    ).length / pageSize) + 1, 1);

    /**if I am in stock container And I was in home containers , and I already had a filter then the slider should follow the previous slider
     *  filter that was setted in state */
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.priceRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    }
    if (!homefilterActivated && buttonSearchClicked) {
      var priceIntervall = this.state.mileageRange;
      var min = 1000 * priceIntervall.slice(1, priceIntervall.indexOf(","));
      var max =
        1000 *
        priceIntervall.slice(
          priceIntervall.indexOf("-") + 3,
          priceIntervall.indexOf(",", priceIntervall.indexOf("-"))
        );
      window.reRenderRangeSliderOtherMileage(min, max);
    }
    /**If I was in stock containers and I did not changed yet the slider in stock container then the slider should be as in filters */
    if (homefilterActivated && !buttonSearchClicked) {
      min = 1000 * priceRange.slice(1, priceRange.indexOf(","));
      max =
        1000 *
        priceRange.slice(
          priceRange.indexOf("-") + 3,
          priceRange.indexOf(",", priceRange.indexOf("-"))
        );
      window.reRenderRangeSliderOther(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSlider();
    }
    if (homefilterActivated && !buttonSearchClicked) {
      var yearIntervall = this.state.yearRange;
      var min = 1 * yearIntervall.slice(1, yearIntervall.indexOf(" -  ") - 0);
      var max = 1 * yearIntervall.slice(yearIntervall.indexOf("-") + 2);
      window.reRenderRangeSliderOtherYear(min, max);
    } else if (!buttonSearchClicked) {
      window.reRenderRangeSliderYear();
    }
    if (!buttonSearchClicked) {
      window.reRenderRangeSliderMileage();
    }

    const carsToRender =
      fetchedStock.length > 0
        ? stock.filter(
          stock => stock.NewUsed.includes("NEW")
        )
        : stockList;
    const visibleCars = carsToRender.length;

    return (
      <section className="m-t-lg-30 m-t-xs-0">
        {" "}
        {/*<div style={{ background: "red", height: "5em", width: "100%" }}></div>*/}
        <div className="row">
          <div className="col-sm-5 col-md-4 col-lg-3 m-b-lg-20" style={{}}>
            {" "}
            <div
              style={{
                background: "#201A20",
                borderRadius: "0.5em 0.5em 0 0",
                padding: "0em",
                width: "100%",
                color: "white",
                marginBottom: "-0.7em",
                textAlign: "center",
              }}
            >
              <h3 className="f-weight-300 f-20" style={{ color: "white" }}>
                <strong>Mega Theron Group</strong>
                <br /> New Vehicles
              </h3>
            </div>
            <Collapse
              style={{ marginTop: window.isPhone ? "" : "-2em" }}
              ghost
              defaultActiveKey={window.isPhone ? [""] : ["1"]}
              onChange={callback}
              expandIcon={({ isActive }) => (
                <div
                  style={{
                    marginLeft: window.isPhone ? "0em" : "8em",
                    display: window.isPhone ? "" : "none",
                    fontSize: "15px",
                  }}
                >
                  {isActive ? (
                    <div style={{ margin: "auto" }}>
                      <EyeInvisibleOutlined /> Close Filter
                    </div>
                  ) : (
                    <div style={{ margin: "auto" }}>
                      <EyeOutlined /> Open Filter
                    </div>
                  )}
                </div>
              )}
            >
              <Panel
                //header={
                //  <div style={{ margin: "auto", marginLeft: 0 }}>Filter</div>
                //}
                key="1"

              //showArrow={false}
              >
                <div className="search-option m-b-lg-50 p-lg-20">
                  {/*<Select
                  placement='topLeft'
                  mode='multiple'
                  allowClear
                  bordered={false}
                  style={{ width: "100%" }}
                  defaultValue={
                    filters !== undefined &&
                    Year !== undefined &&
                    Year !== ""
                      ? Year
                      : Year
                  }
                  //value={!this.state.resetValue ? null : Year}
                  placeholder='Year'
                  onChange={this.setCarYear}
                >
                  <Option value=''>All Years</Option>

                  {this.props.fetchedStock
                    .sort((a, b) => b.Year - a.Year)
                    .map((stock, index) => {
                      if (duplicateCheck.includes(stock.Year))
                        return null;
                      duplicateCheck.push(stock.Year);
                      return (
                        <Option key={index} value={stock.Year}>
                          {stock.Year}
                        </Option>
                      );
                    })
                    .filter((e) => e)}
                </Select>*/}
                  <button
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginLeft: "-0.2em",
                      marginTop: "-1em",
                    }}
                    className="form-item"
                  >
                    Years
                  </button>
                  <div
                    id="spanrangeyear"
                    className="slider-range-year m-b-lg-5 m-t-lg-10"
                  ></div>
                  <input
                    id="yearslider"
                    type="text"
                    disabled
                    className="slider_amount_year"
                    value={
                      yearRange !== undefined && homefilterActivated
                        ? yearRange
                        : this.state.yearRange
                    }
                  />
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined && Brand !== undefined && Brand !== ""
                        ? Brand
                        : Brand
                    }
                    placeholder="Make"
                    onChange={this.setCarMake}
                  >
                    <Option value="">All Makes</Option>
                    {this.props.fetchedStock &&
                      this.props.fetchedStock.length > 0 &&
                      this.props.fetchedStock
                        .filter(stock => stock && stock.Brand && stock.NewUsed[0] === "NEW" && stock.Brand.length > 0) // Filter out items with missing or empty Brand property
                        .flatMap(stock => stock.Brand.map(brand => brand)) // Flatten the array of brands
                        .sort((a, b) => a.localeCompare(b)) // Sort the brands alphabetically
                        .map((brand, index) => {
                          if (duplicateCheck.includes(brand)) return null;
                          duplicateCheck.push(brand);
                          return (
                            <Option
                              //style={{ textTransform: "uppercase" }}
                              key={index}
                              value={brand}
                            >
                              {brand}
                            </Option>
                          );
                        })
                        .filter(e => e)}
                  </Select>
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        Brand !== undefined &&
                        Brand !== ""
                        ? Brand
                        : Brand
                    }
                    placeholder="Model"
                    onChange={this.setCarModel}
                  >
                    <Option value="">All Models</Option>
                    {this.props.fetchedStock &&
                      this.props.fetchedStock.length > 0 &&
                      this.props.fetchedStock
                        .filter(
                          (stock) =>
                            stock && stock.ModelRange && stock.NewUsed[0] === "NEW" && stock.ModelRange.length > 0 && stock.ModelRange !== null
                        ) // Filter out items with missing or empty ModelRange property
                        .flatMap((stock) => stock.ModelRange.map((brand) => brand)) // Flatten the array of brands
                        .filter((brand) => brand.trim() !== "") // Filter out any empty or invalid brands
                        .sort((a, b) => a.localeCompare(b)) // Sort the brands alphabetically
                        .map((brand, index) => {
                          if (duplicateCheck.includes(brand)) return null;
                          duplicateCheck.push(brand);
                          return (
                            <Option
                              key={index}
                              value={brand}
                            >
                              {brand}
                            </Option>
                          );
                        })
                        .filter((e) => e)}
                  </Select>
                  <button
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginLeft: "-0.2em",
                      display: "none",
                    }}
                    className="form-item"
                  >
                    Mileage
                  </button>
                  <div
                    style={{ display: "none" }}
                    id="spanrangeMileage"
                    className="slider-range-Mileage m-b-lg-5 m-t-lg-10"
                  ></div>
                  <input
                    style={{ display: "none" }}
                    id="mileageslider"
                    type="text"
                    disabled
                    className="slider_amount_Mileage"
                    value={
                      mileageRange !== undefined && homefilterActivated
                        ? mileageRange
                        : this.state.mileageRange
                    }
                  />
                  <Divider />
                  <Select
                    mode='multiple'
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        NewUsed !== undefined &&
                        NewUsed !== ""
                        ? NewUsed
                        : NewUsed
                    }
                    placeholder="Condition"
                    onChange={this.setCarCondition}
                  >
                    <Option value="">All Conditions</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.NewUsed[0].localeCompare(b.NewUsed[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.NewUsed[0])) return null;
                        duplicateCheck.push(stock.NewUsed[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.NewUsed[0]}
                          >
                            {stock.NewUsed[0]}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  <Divider />{" "}
                  {/*<button
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginLeft: "-0.2em",
                marginBottom: "0.4em",
              }}
              className='form-item'
            >
              Mileage
            </button>
            <Slider
              style={{
                margin: "auto",
                marginTop: "-0.8em",
                marginBottom: "2.5em",
                width: "90%",
              }}
              max={500000}
              min={0}
              tooltipPlacement='bottom'
              range
              defaultValue={[0, 500000]}
              tooltipVisible
            />
            <button
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginLeft: "-0.2em",
                marginBottom: "0.4em",
              }}
              className='form-item'
            >
              Price
            </button>
            <Slider
              style={{
                margin: "auto",
                marginTop: "-0.8em",
                marginBottom: "2.5em",
                width: "90%",
              }}
              max={500000}
              min={0}
              tooltipPlacement='bottom'
              range
              defaultValue={[0, 500000]}
              tooltipVisible
            />*/}
                  <Divider style={{}} />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        BodyType !== undefined &&
                        BodyType !== ""
                        ? BodyType
                        : BodyType
                    }
                    placeholder="Body Type"
                    onChange={this.setCarBody}
                  >
                    <Option value="">All Body Types</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.BodyType[0].localeCompare(b.BodyType[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.BodyType[0])) return null;
                        if (stock.BodyType.includes("")) return null;
                        duplicateCheck.push(stock.BodyType[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.BodyType[0]}
                          >
                            {stock.BodyType[0]}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        Transmission !== undefined &&
                        Transmission !== ""
                        ? Transmission
                        : Transmission
                    }
                    placeholder="Transmission"
                    onChange={this.setCarTransmission}
                  >
                    <Option value="">All Transmissions</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.Transmission[0].localeCompare(b.Transmission[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.Transmission[0])) return null;
                        if (stock.Transmission.includes("")) return null;
                        duplicateCheck.push(stock.Transmission[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.Transmission[0]}
                          >
                            {stock.Transmission[0]}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  <Divider />
                  <Select
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    // value={this.state.Model}
                    placeholder="Drive Train"
                    onChange={this.setCarDriveTrain}
                  >
                    {!this.state.Model || this.state.Model === "4X2" ? (
                      <Option value="4X2">4X2</Option>
                    ) : null}
                    {!this.state.Model || this.state.Model === "4X4" ? (
                      <Option value="4X4">4X4</Option>
                    ) : null}
                  </Select>
                  {/* <Divider />
                <Select
                  mode="multiple"
                  allowClear
                  placement="topLeft"
                  bordered={false}
                  style={{ width: "100%" }}
                  defaultValue={
                    filters !== undefined &&
                      vehicleDrive !== undefined &&
                      vehicleDrive !== ""
                      ? vehicleDrive
                      : vehicleDrive
                  }
                  placeholder="Drive Train"
                  onChange={this.setCarDriveTrain}
                >
                  <Option value="">All Drive Trains</Option>
                  {this.props.fetchedStock
                    .sort((a, b) => a.vehicleDrive[0].localeCompare(b.vehicleDrive[0]))
                    .map((stock, index) => {
                      if (duplicateCheck.includes(stock.vehicleDrive[0])) return null;
                      if (stock.vehicleDrive.includes("")) return null;
                      duplicateCheck.push(stock.vehicleDrive[0]);
                      return (
                        <Option
                          //style={{ textTransform: "uppercase" }}
                          key={index}
                          value={stock.vehicleDrive[0]}
                        >
                          {stock.vehicleDrive[0]}
                        </Option>
                      );
                    })
                    .filter(e => e)}
                </Select> */}
                  {/*<Divider />
            <Select
            mode="multiple"
              allowClear
               placement='topLeft'
              bordered={false}
              style={{ width: "100%" }}
              defaultValue={
                filters !== undefined &&
                Features !== undefined &&
                Features !== ""
                  ? Features
                  : null
              }
              placeholder="Features"
              onChange={this.setCarFeatures}
            >
              <Option key={1} value="">
                All Features
              </Option>
              <Option key={2} value="Sunroof">
                Sunroof
              </Option>
              <Option key={3} value="Towbar">
                Towbar
              </Option>
            </Select>*/}
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        DealershipName !== undefined &&
                        DealershipName !== ""
                        ? DealershipName
                        : DealershipName
                    }
                    placeholder="Branch"
                    onChange={this.setCarBranch}
                  >
                    <Option value="">All Branches</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.DealershipName[0].localeCompare(b.DealershipName[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.DealershipName[0])) return null;
                        if (stock.DealershipName.includes("")) return null;
                        duplicateCheck.push(stock.DealershipName[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.DealershipName[0]}
                          >
                            {stock.DealershipName[0].replace(/Mega Theron Group \(Pty\) Ltd t\/a|Mega Theron Group \(Pty\) Ltd  t\/a|MTG Klerksdorp t\/a/g, '')}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        Colour !== undefined &&
                        Colour !== ""
                        ? Colour
                        : Colour
                    }
                    placeholder="Colour"
                    onChange={this.setCarColour}
                  >
                    <Option value="">All Colours</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.Colour[0].localeCompare(b.Colour[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.Colour[0])) return null;
                        if (stock.Colour.includes("")) return null;
                        duplicateCheck.push(stock.Colour[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.Colour[0]}
                          >
                            {stock.Colour[0]}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  <Divider />
                  <Select
                    mode="multiple"
                    allowClear
                    placement="topLeft"
                    bordered={false}
                    style={{ width: "100%" }}
                    defaultValue={
                      filters !== undefined &&
                        FuelType !== undefined &&
                        FuelType !== ""
                        ? FuelType
                        : FuelType
                    }
                    placeholder="Fuel Type"
                    onChange={this.setCarFuelType}
                  >
                    <Option value="">All Fuel Types</Option>
                    {this.props.fetchedStock
                      .filter(stock => stock && stock.NewUsed[0] === "NEW")
                      .sort((a, b) => a.FuelType[0].localeCompare(b.FuelType[0]))
                      .map((stock, index) => {
                        if (duplicateCheck.includes(stock.FuelType[0])) return null;
                        if (stock.FuelType.includes("")) return null;
                        duplicateCheck.push(stock.FuelType[0]);
                        return (
                          <Option
                            //style={{ textTransform: "uppercase" }}
                            key={index}
                            value={stock.FuelType[0]}
                          >
                            {stock.FuelType[0]}
                          </Option>
                        );
                      })
                      .filter(e => e)}
                  </Select>
                  {/* <Divider />
                <button
                  className="form-item"
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginLeft: "-0.2em",
                  }}
                >
                  On Sale
                  <Checkbox
                    style={{ float: "right" }}
                    type="checkbox"
                    id="true"
                    onClick={this.setCarOnSale}
                  ></Checkbox>
                </button>{" "} */}
                  {/*<button style={{ textAlign: "left" }} className='form-item'>
              Price
            </button>*/}{" "}
                  <button
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginLeft: "-0.2em",
                      marginTop: "-1em",
                    }}
                    className="form-item"
                  >
                    Price
                  </button>
                  <div
                    id="spanrange"
                    className="slider-range m-b-lg-5 m-t-lg-10"
                  ></div>
                  <input
                    id="priceslider"
                    type="text"
                    disabled
                    className="slider_amount"
                    value={
                      priceRange !== undefined && homefilterActivated
                        ? priceRange
                        : this.state.priceRange
                    }
                  />
                  <button
                    type="button"
                    className="ht-btn ht-btn-default "
                    style={{
                      backgroundColor: "transparent",
                      height: "2em",
                      color: "red",
                    }}
                    onClick={() => this.viewAllStock()}
                  >
                    <i className="fa fa-refresh"></i>Reset Fields
                  </button>
                  <button
                    type="button"
                    className="ht-btn ht-btn-default m-t-lg-10"
                    onClick={() => this.buttonSearchClicked()}
                  >
                    <i className="fa fa-search"></i>Search Now
                  </button>
                  {/*<Button onClick={this.viewAllStock}>Reset</Button>*/}
                </div>
              </Panel>
            </Collapse>
            {/*<div className='clearfix'></div>
          <div className='banner-item banner-bg-4 banner-1x color-inher desktop-view'>
            <h5>BOOK A SERVICE</h5>
            <h3 className='f-weight-300'>
              <strong>TRUSTWORTHY </strong> CAR SERVICES
            </h3>
            <p>Car in need of a service? Book one now. </p>
            <span onClick={this.scrollOnclick}>
              <Link to='/servicecenter' className='ht-btn ht-btn-default'>
                Book Now
              </Link>
            </span>
          </div>*/}
          </div>
          <div className="col-sm-7 col-md-8 col-lg-9">
            <div className="product product-grid product-grid-2 stock">
              <div className="heading heading-2 m-b-lg-0">
                <h3 className="p-l-lg-20">
                  We Found{" "}
                  <span style={{ color: "red" }}>
                    {fetchedStock.length > 0 ? visibleCars : "0"} Offers
                  </span>{" "}
                </h3>
              </div>
              <div className="product-filter p-t-xs-20 p-l-xs-20">
                <div className="m-b-xs-10 pull-left">
                  <a
                    onClick={() => this.setDisplayType("cards")}
                    className={
                      this.state.displayType === "cards" ? "active" : ""
                    }
                  >
                    <i className="fa fa-th" />
                  </a>
                  {/* <a
                    onClick={() => this.setDisplayType("list")}
                    className={
                      this.state.displayType === "list" ? "active" : ""
                    }
                  >
                    <i className="fa fa-th-list" />
                  </a> */}
                </div>
                <div className="pull-right">
                  <div className="pull-left">
                    <div className="select-wrapper">
                      <label>
                        <i className="fa fa-sort-alpha-asc" />
                        Show :{" "}
                      </label>
                      <div className="dropdown pull-left">
                        <button
                          className="dropdown-toggle form-item w-80"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          {this.state.pageSize}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li>
                            <a onClick={() => this.setPageSize(6)}>6</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(12)}>12</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(24)}>24</a>
                          </li>
                          <li>
                            <a onClick={() => this.setPageSize(fetchedStock.length)}>
                              All
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className="pull-right "
                  style={{
                    marginRight: window.isPhone ? "0em" : "15px",
                    marginTop: window.isPhone ? "0.5em" : "",
                  }}
                >
                  <div className="pull-left">
                    <div className="select-wrapper">
                      <label>
                        <i className="fa fa-sort-amount-desc" />
                        Price :{" "}
                      </label>
                      <div className="dropdown pull-left">
                        <button
                          className="dropdown-toggle form-item w-135"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          {this.state.priceSort}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li>
                            <a onClick={this.onSortChangeFromLow}>
                              Low to High
                            </a>
                          </li>
                          <li>
                            <a onClick={this.onSortChangeFromHigh}>
                              High to Low
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row">{this.renderStock()}</div>
              <nav aria-label="Page navigation">
                <ul
                  className="pagination ht-pagination"
                // onClick={(document.documentElement.scrollTop = 0)}
                >
                  <li>
                    <a
                      aria-label="Previous"
                      style={
                        this.state.activePage === 1 || fetchedStock.length !== 0
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <span aria-hidden="true">
                        <i
                          className="fa fa-chevron-left"
                          onClick={() => {
                            this.setActivePage(this.state.activePage - 1);
                          }}
                        />
                      </span>
                    </a>
                  </li>
                  {table.map(i => {
                    if (table.length <= 1) return "";
                    else {
                      return (
                        <li
                          key={i}
                          className={
                            this.state.activePage === i ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              this.setActivePage(i);
                            }}
                          >
                            {i}
                          </a>
                        </li>
                      );
                    }
                  })}
                  <li>
                    <a
                      aria-label="Next"
                      style={
                        this.state.activePage ===
                          Math.ceil(fetchedStock.length / this.state.pageSize) ||
                          fetchedStock.length !== 0
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <span aria-hidden="true">
                        <i
                          className="fa fa-chevron-right"
                          onClick={() => {
                            this.setActivePage(this.state.activePage + 1);
                          }}
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
/**fetchedStock is the reducer state for the stock fetched from the API ,
 *  and homefilterActivated is to know if the user entred this page throught the home filter or not */
function mapStateToProps({ fetchedStock, homefilterActivated }) {
  return { fetchedStock, homefilterActivated };
}

export default connect(mapStateToProps, {
  fetchFiltredStock,
  turnOffHomefilter,
})(StockContainer);
