import React, { Component } from "react";
import { Row, Col, Button, Table, Space, Modal, Form, Input } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const columns = [
  {
    title: "Newsletters",
    dataIndex: "name",
    key: "name",
    render: (text) => <span>{text}</span>,
  },
  {
    title: "View",
    key: "downloadAction",
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/${encodeURIComponent(record.download)}`}>
          <i
            style={{
              color: "white",
              background: "red",
              borderRadius: "3em",
              padding: "1em",
            }}
            className="fa fa-eye"
            aria-hidden="true"
          ></i>
        </Link>
      </Space>
    ),
  },
];

// Example data to test
const data = [
  // {
  //   key: "1",
  //   name: "THE ROAD AHEAD IS BRIGHT TOGETHER",
  //   download: "bright-together",
  // },
  {
    key: "2",
    name: "Mega Theron Group's Journey to Record-Breaking Success",
    download: "driving-excellence",
  },
];

class Newsletters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalChat = () => {
    this.setState({
      visibleCallback: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visibleCallback: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visibleCallback: false,
    });
  };

  render() {
    return (
      <section className="m-t-lg-30 m-t-xs-0">
        {" "}
        {window.isPhone ? (
          <img
            style={{
              width: "106vw",
              position: "absolute",
              height: "12em",
              objectFit: "cover",
              marginTop: "0.35em",

              right: 0,
              zIndex: 0,
            }}
            src={`${process.env.PUBLIC_URL}/images/newsletter-banner-mobile.png`}
            alt="logo"
          />
        ) : (
          <img
            style={{
              width: "106vw",
              position: "absolute",
              height: "11.5em",
              objectFit: "cover",
              marginTop: "3.5em",
              right: 0,
              zIndex: 0,
            }}
            src={`${process.env.PUBLIC_URL}/images/newsletter-banner.png`}
            alt="logo"
          />
        )}
        <div className="row sm-5 m-b-lg-60">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="banner-item-home  no-bg color-inher-home text-align-lg-right ">
              <Row
                style={{
                  margin: "auto",
                  paddingTop: window.isPhone ? "3.5em" : "5.5em",
                  paddingBottom: "1em",
                }}
              >
                <Col xs={24} md={15} style={{ textAlign: "left" }}>
                  <h1
                    style={{
                      color: "white",
                      marginTop: window.isPhone ? "-1.3em" : "-0.4em",
                    }}
                    className={
                      window.isPhone ? "f-weight-600 f-20" : "f-weight-600 f-20"
                    }
                  >
                    REVVED-UP INSIGHTS
                  </h1>
                  <h1
                    style={{
                      color: "white",
                      marginTop: window.isPhone ? 0 : 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginBottom: window.isPhone ? "" : "",
                      lineHeight: "1em",
                    }}
                    className={
                      window.isPhone ? "f-weight-600 f-30" : "f-weight-600 f-40"
                    }
                  >
                    COMPANY{" "}
                    <span style={{ color: "red", lineHeight: "1em" }}>
                      NEWSLETTER
                    </span>{" "}
                    <br />
                  </h1>
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    Stay in the know with all things automotive, right here.
                  </p>
                </Col>
                {/* <Col xs={11} md={4}>
                  <Button
                    onClick={this.showModalChat}
                    style={{
                      border: "1px solid rgba(255, 255, 255, 0.962)",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0.3em",
                      backgroundColor: "rgba(255, 255, 255, 0.962)",
                      color: "#F91700",
                      height: "55px",
                      paddingTop: "0.2em",
                      paddingLeft: window.isPhone ? "1em" : "3em",
                      paddingRight: window.isPhone ? "1em" : "3em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: window.isPhone ? "2.65em" : "1.65em",
                      marginLeft: window.isPhone ? "" : "2em",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Chat to a Specialist
                  </Button>
                </Col>
                <Col xs={1} md={0} />
                <Col xs={11} md={4}>
                  {window.isPhone ? (
                    <a href={"tel:087 152 3159"}>
                      <Button
                        style={{
                          border: "1px solid #F91700",
                          fontSize: "15px",
                          fontWeight: 600,
                          borderRadius: "0.3em",
                          backgroundColor: "#F91700",
                          color: "white",
                          height: "55px",
                          paddingTop: "0.2em",
                          paddingLeft: window.isPhone ? "2em" : "3em",
                          paddingRight: window.isPhone ? "2em" : "3em",
                          textShadow: "none",
                          margin: "auto",
                          marginTop: "2.65em",
                          marginLeft: "1em",
                        }}
                        type="primary"
                        htmlType="submit"
                      >
                        Call Now
                      </Button>
                    </a>
                  ) : (
                    <Button
                      onClick={this.showModal}
                      style={{
                        border: "1px solid #F91700",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "#F91700",
                        color: "white",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: window.isPhone ? "2em" : "3em",
                        paddingRight: window.isPhone ? "2em" : "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "1.65em",
                        marginLeft: "1em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Call Now
                    </Button>
                  )}
                </Col> */}
              </Row>
            </div>
          </div>
        </div>
        <section className="block-contact m-t-lg-30 m-t-xs-0">
          <div className="">
            <div className="row">
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{ marginBottom: window.isPhone ? "1em" : "2em" }}
              >
                <Table
                  //expandable={{
                  //  expandedRowRender: (record) => (
                  //    <p style={{ margin: 0 }}>{record.description}</p>
                  //  ),
                  //  rowExpandable: (record) => record.name !== "Not Expandable",
                  //}}
                  pagination={false}
                  columns={columns}
                  dataSource={data}
                />{" "}
              </div>{" "}
              <div
                className="desktop-view"
                //className="banner-img-home"
                style={{
                  width: "100vw",
                  height: "50em",
                  marginTop: "105em",

                  backgroundImage:
                    "url(" +
                    `${process.env.PUBLIC_URL}/images/download-banner.png` +
                    ")",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  left: 0,
                  zIndex: -99,
                  opacity: 0.8,
                }}
                //  src={`${process.env.PUBLIC_URL}/images/download-banner.png`}
                alt="logo"
              />
            </div>{" "}
          </div>{" "}
        </section>
        <Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="Logo"
            style={{ width: "65%", margin: "auto" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Need more assistance?
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              Feel free to give us a call.
            </span>{" "}
            <br /> <br />
            <a href={"tel:087 152 3159"}>
              <Button
                // onClick={this.showModal}
                style={{
                  border: "1px solid #F91700",
                  fontSize: "15px",
                  fontWeight: 600,
                  borderRadius: "0.3em",
                  backgroundColor: "#F91700",
                  color: "white",
                  height: "55px",
                  paddingTop: "0.2em",
                  paddingLeft: window.isPhone ? "2em" : "3em",
                  paddingRight: window.isPhone ? "2em" : "3em",
                  textShadow: "none",
                  margin: "auto",
                  // marginTop: "3em",
                  // marginLeft: "1em",
                }}
                type="primary"
                htmlType="submit"
              >
                <i style={{ marginRight: "0.5em" }} className="fa fa-phone"></i>
                {"  "}087 152 3159
              </Button>
            </a>
            <br /> <br />
            <span style={{ color: "black ", fontSize: "16px" }}>
              Our friendly vehicle specialists are ready to assist.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal>
        <Modal
          visible={this.state.visibleCallback}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            initialValues={{
              remember: true,
            }}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            onFinish={this.handleSubmit}
            style={{ maxWidth: "80vw", margin: "auto" }}
          >
            <div>
              <div className="row">
                {this.state.leadReference ? (
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="Logo"
                      style={{ width: "65%", margin: "auto" }}
                    />
                    <h3
                      style={{
                        marginTop: "1em",
                        color: "black",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      Thank you for contacting
                      <br />
                      <span style={{ color: "rgb(214, 54, 41)" }}>
                        The Mega Theron Group.
                      </span>{" "}
                      <br />
                      <br />
                      <span style={{ color: "black ", fontSize: "17px" }}>
                        Our vehicle specialist will be in touch shortly.
                      </span>{" "}
                      <br /> <br />
                      <span
                        style={{ color: "rgb(214, 54, 41)", fontSize: "14px" }}
                      >
                        {this.state.leadReference && (
                          <p>
                            Your Reference Code: <br />
                            {this.state.leadReference}
                          </p>
                        )}
                      </span>{" "}
                      <br />
                    </h3>{" "}
                    <br />
                  </span>
                ) : (
                  <span>
                    <div
                      style={{
                        textAlign: window.isPhone ? "center" : "",
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingBottom: 20,
                      }}
                      className="banner-item banner-bg-10 color-inher m-t-lg-30"
                    >
                      <h3
                        className="f-weight-300"
                        style={{ marginBottom: "0em" }}
                      >
                        <strong style={{ lineHeight: "1em" }}>
                          Get a Callback
                        </strong>
                      </h3>
                      <p style={{ margin: "auto" }}>
                        Speak to a Vehicle Specialist today!
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledInput
                          minLength={3}
                          placeholder="First Name"
                          type="text"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.handleInputChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Form.Item
                        type="text"
                        name="surname"
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledInput
                          minLength={3}
                          placeholder="Surname"
                          type="text"
                          name="surname"
                          value={this.state.surname}
                          onChange={this.handleInputChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <Form.Item
                        name="cellPhone"
                        rules={[
                          { required: true },
                          {
                            min: 10,
                            message: "Number must have a minimum of 10 digits",
                          },
                          {
                            max: 10,
                            message: "Number must have a maximum of 10 digits",
                          },
                        ]}
                        hasFeedback
                      >
                        <StyledInput
                          style={{ marginBottom: 0 }}
                          placeholder="Number"
                          type="number"
                          name="cellPhone"
                          value={this.state.cellPhone}
                          onChange={this.handleInputChange}
                        />
                      </Form.Item>
                    </div>
                    <br />
                    <Button
                      disabled={
                        this.state.submitDisabled === true ? true : false
                      }
                      style={{
                        border: "1px solid #F91700",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "#F91700",
                        color: "white",
                        height: "45px",
                        width: "95%",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "0em",
                      }}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <p
                      style={{
                        color: "black",
                        //marginBottom: "2.5em",
                        marginTop: "1em",
                        fontSize: "13px",
                        lineHeight: "1.5em",
                      }}
                    >
                      By submitting this form, you agree to our{" "}
                      <span
                        style={{
                          fontSize: "13px",
                          textDecoration: "underline",
                        }}
                      >
                        <Link style={{ color: "black" }} to="/terms">
                          Terms of Use
                        </Link>
                      </span>{" "}
                      and{" "}
                      <span
                        style={{
                          fontSize: "13px",
                          textDecoration: "underline",
                        }}
                      >
                        <Link style={{ color: "black" }} to="/privacy-policy">
                          Privacy Statement
                        </Link>
                      </span>
                      .<br />
                      {this.state.errorMessage && (
                        <p>Error: {this.state.errorMessage}</p>
                      )}
                    </p>{" "}
                  </span>
                )}
              </div>
            </div>
          </Form>
        </Modal>
      </section>
    );
  }
}

export default Newsletters;
